<script setup lang="ts">
import MainTitle from '@/components/MainTitle.vue'
import MainMenuCollection from '@/components/MainMenuCollection.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import { useAuth0 } from '@auth0/auth0-vue'

const { isLoading } = useAuth0()

import algoliasearch from 'algoliasearch/lite'
const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALOGLIA_API_KEY
)
</script>

<template>
  <ais-instant-search
    :search-client="searchClient"
    :insights="true"
    :future="{ preserveSharedStateOnUnmount: true }"
    index-name="library"
  >
    <div v-if="isLoading">Opening the Cyentia Library...</div>
    <div v-else>
      <div class="tw-grid tw-grid-cols-1 tw-px-8 tw-py-8">
        <nav class="tw-row-span-1">
          <NavigationBar />
        </nav>
        <header class="tw-justify-self-center tw-leading-6"></header>

        <main class="tw-mt-10 tw-min-h-[800px]">
          <RouterView />
        </main>
      </div>
    </div>
  </ais-instant-search>
</template>

<style scoped></style>
