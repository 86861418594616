<template>
  <div display="inline">
    <button
      @click="onLogin"
      class="tw-btn tw-btn-primary tw-btn-sm tw-transition-colors hover:tw-bg-white hover:tw-text-black"
    >
      Log in
    </button>
  </div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'

const { loginWithRedirect } = useAuth0()

const onLogin = () => {
  loginWithRedirect({})
}
</script>
