export default {
  accessToken: import.meta.env.VITE_ROLLBAR_API_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'dev',
    client: {
      javascript: {
        code_version: '1.0.0'
      }
    }
  }
}
