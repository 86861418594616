import { createWebHistory, createRouter } from 'vue-router'
import { authGuard } from '@auth0/auth0-vue'

const routes = [
  { path: '/', name: 'home', component: () => import('@/pages/HomeView.vue') },
  { path: '/search', name: 'search', component: () => import('@/pages/SearchView.vue') },
  {
    path: '/browse/:page',
    name: 'browse',
    component: () => import('@/pages/BrowseView.vue'),
    props: (route) => ({ page: Number(route.params.page) })
  },
  {
    path: '/browse',
    component: () => import('@/pages/BrowseView.vue'),
    props: { page: 1 }
  },
  {
    path: '/chat',
    name: 'chat',
    beforeEnter: authGuard,
    component: () => import('@/pages/ChatView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    beforeEnter: authGuard,
    component: () => import('@/pages/ProfileView.vue')
  },
  {
    path: '/report/:docId',
    name: 'report',
    component: () => import('@/pages/ReportView.vue'),
    props: (route) => ({ docId: Number(route.params.docId) })
  },
  { path: '/about', name: 'about', component: () => import('@/pages/AboutView.vue') },
  {
    path: '/charts/:docId',
    name: 'charts',
    component: () => import('@/pages/ChartView.vue'),
    props: (route) => ({ docId: Number(route.params.docId) })
  },
  {
    path: '/contribute',
    name: 'contribute',
    component: () => import('@/pages/ContributeView.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('@/pages/ContributeView.vue'),
    beforeEnter: authGuard
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router
