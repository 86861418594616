<template>
  <div display="inline">
    <button
      @click="handleSignUp"
      class="tw-btn tw-btn-primary tw-btn-sm tw-transition-colors hover:tw-bg-white hover:tw-text-black"
    >
      Sign up
    </button>
  </div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'

const { loginWithRedirect } = useAuth0()

const handleSignUp = () => {
  loginWithRedirect({
    authorizationParams: {
      screen_hint: 'signup'
    }
  })
}
</script>
