<template>
  <section
    class="tw-navbar tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-z-50 tw-flex tw-items-center tw-justify-end tw-bg-base-300 tw-py-3 md:tw-right-40"
  >
    <div class="tw-flex-1">
      <RouterLink to="/">
        <img
          alt="Cyentia logo"
          class="tw-mr-2 tw-border tw-bg-white max-md:tw-ml-4 lg:tw-mr-4"
          src="@/assets/logo.svg"
          width="120"
          height="60"
      /></RouterLink>
      <a
        class="tw-btn tw-btn-accent tw-btn-md tw-animate-pulse tw-text-sm md:tw-ml-4 lg:tw-text-base"
        href="https://docs.google.com/forms/d/e/1FAIpQLSee3OqYaqPOvzi48PJr23RBV34ewcb8AXn5EZ2AP3rA9Y5jAg/viewform?usp=sf_link"
        target="_blank"
        >Testers, make<br />your feedback heard!</a
      >
    </div>
    <div class="tw-flex-none">
      <RouterLink
        to="/browse"
        class="tw-btn tw-btn-link tw-px-1 tw-text-base-content lg:tw-btn-lg lg:tw-px-3"
        >Browse</RouterLink
      >
      <RouterLink
        to="/search"
        class="tw-btn tw-btn-link tw-px-1 tw-text-base-content lg:tw-btn-lg lg:tw-px-3"
        >Search</RouterLink
      >
      <RouterLink
        v-if="isAuthenticated"
        to="/chat"
        class="tw-btn tw-btn-link tw-px-1 tw-text-base-content lg:tw-btn-lg lg:tw-px-3"
      >
        Chat
      </RouterLink>
      <RouterLink
        to="/about"
        class="tw-btn tw-btn-link tw-px-1 tw-text-base-content lg:tw-btn-lg lg:tw-px-3"
        >About</RouterLink
      >
      <ButtonLogin class="tw-mr-2" v-if="!isAuthenticated" />
      <div class="tw-dropdown tw-dropdown-end" v-if="isAuthenticated">
        <div tabindex="0" role="button" class="tw-btn tw-avatar tw-btn-circle tw-btn-ghost">
          <div class="tw-w-10 tw-rounded-full">
            <img v-bind:src="user?.picture" alt="avatar" />
          </div>
        </div>
        <ul
          tabindex="0"
          class="tw-menu tw-dropdown-content tw-menu-sm tw-z-[1] tw-mt-3 tw-w-52 tw-rounded-box tw-bg-base-100 tw-p-2 tw-shadow"
        >
          <li><RouterLink :to="{ name: 'profile' }">Profile</RouterLink></li>
          <li><button @click="onLogout">Log out</button></li>
        </ul>
      </div>
      <ButtonSignup class="tw-mr-3 md:tw-mr-4" v-if="!isAuthenticated" />
      <input
        type="checkbox"
        class="tw-toggle tw-toggle-sm md:tw-toggle-md"
        @click="toggleDark()"
        checked
      />
      <IconThemeLightDark class="tw-mr-4 hover:tw-cursor-pointer" @click="toggleDark()" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useDark, useToggle } from '@vueuse/core'
import IconThemeLightDark from '@/components/icons/IconThemeLightDark.vue'
import ButtonSignup from '@/components/buttons/ButtonSignup.vue'
import ButtonLogin from '@/components/buttons/ButtonLogin.vue'
const isDark = useDark({
  attribute: 'data-theme',
  valueDark: 'dark',
  valueLight: 'light',
  storageKey: 'library-color-theme'
})
const toggleDark = useToggle(isDark)

import { useAuth0 } from '@auth0/auth0-vue'
const { isAuthenticated } = useAuth0()

const { logout, user } = useAuth0()

const onLogout = () => {
  logout({
    logoutParams: {
      returnTo: import.meta.env.VITE_AUTH0_CALLBACK_URL
    }
  })
}
</script>
